import { OptionDto } from "./Option";
import { ShiftColor } from "./ShiftColor";

export type QuestionUserGroupFilter = {
    0?: number[],
    1?: number[],
    2?: number[],
    3?: number[],
    4?: number[],
    5?: number[]
}

export interface IsolatedInputDetails {
    isFirst: boolean;
    isLast: boolean;
}

export interface IsolatedOptionDetails {
    isFirst: boolean;
    isLast: boolean;
    order: number;
}

export interface IsolatedRowDetails {
    columnIndex: number;
    rowIndex: number;
    value: string | null;
}

export interface IsolatedTableRowDetails {
    rowIndex: number;
    columns: IsolatedTableColumnDetails[]
}

export interface IsolatedTableColumnDetails {
    columnIndex: number,
    optionId?: number,
    optionName?: string,
    availableOptions: OptionDto[]
}

export interface FormDto {
    formId: number;
    name: number;
    dateCreated: Date;
    dateUpdated: Date;
    departmentId: number;
    userId: number;
    questionGroups: QuestionGroupDto[];
    calendars: CalendarDto[]
}

export interface QuestionGroupDto {
    questionGroupId: number;
    name: string;
    order: number;
    formId: number;
    questions: QuestionDto[];
}

export interface CalendarDto {
    calendarId: number;
    date: Date;
    year: number;
    month: number;
    day: number;
    quarter: number;
    week: number;
    dayOfWeek: number;
    shifts: ShiftDto[];
}

export interface ShiftDto {
    shiftId: number;
    name: string;
    color: ShiftColor;
}

export interface QuestionGroupDto {
    questionGroupId: number;
    name: string;
    order: number;
    formId: number;
    questions: QuestionDto[]
}

export interface QuestionDto {
    questionId: number;
    order: number;
    questionText: string;
    optionGroupId? : number,
    questionGroupId?: number,
    answers: AnswerDto[]
}

export interface QuestionDto {
    questionId: number;
    order: number;
    questionText: string;
    answers: AnswerDto[];
    limit?: number
}

export interface AnswerDto {
    answerId?: number,
    answerType: AnswerType,
    questionId: number,
    order: number,
    date: Date,
    dateCreated: Date,
    dateUpdated: Date,
    user?: AnswerUserReferenceDto,
    option?: AnswerOptionReferenceDto,
    textAnswerValue?: string,
    flagAnswerValue?: boolean,
    strikeTeamIncidentName?: string,
    strikeTeamOptionId?: number,
    strikeTeamReferences? : Array<AnswerStrikeTeamReferenceDto>
}

export interface AnswerStrikeTeamReferenceDto {
    answerStrikeTeamOptionReferenceId: number
    answerId: number
    optionId?: number
    userId?: number,
    optionName?: string
    type: StrikeTeamType
    order: number
}

export enum AnswerType {
    Option,
    Flag,
    Text,
    User,
    StrikeTeam
}

export enum StrikeTeamType {
    StrikeTeam,
    Engine
}

export interface ReferenceDetails {
    optionId?: number,
    optionName?: string,
    userId?: number,
    order: number,
    strikeTeamType?: StrikeTeamType
}

export interface AnswerOptionReferenceDto {
    answerId: number,
    optionId?: number
    optionName?: string
}

export interface AnswerUserReferenceDto {
    answerId?: number;
    userId?: number;
    userName?: string;
}

export interface OptionSelectionDetails {
    columnIndex: number,
    optionId?: number,
    optionName?: string,
    index?: number
}

export enum GlendaleModuleOrder {
    OperationsBC = 0,
    StaffCaptain = 1,
    BadgeBand = 2,
    Flags = 3,
    ShiftActivity = 4,
    WildlandSpecialtyApparatus = 5,
    StrikeTeams = 6,
    ReserveApparatusStatus = 7,
    OnCallList = 8,
    UsarResponse = 9,
    HazmatResponse = 10,
    PublicEducation = 11,
    CommunityRoom = 12,
    SmallConferenceRoom = 13
}

export enum MontereyParkModuleOrder {
    OperationsBC = 0,
    BadgeBand = 1,
    Flags = 2,
    ShiftActivity = 3,
    WildlandSpecialtyApparatus = 4,
    StrikeTeams = 5,
    ReserveApparatusStatus = 6,
    OnCallList = 7,
    UsarStatus = 8,
    UsarResponse = 9,
    AdditionalEMSResponse = 10,
    CommunityRiskReduction = 11,
    EmergencyOperationsCenter = 12
}

export enum RiversideModuleOrder {
    OperationsBC = 0,
    BadgeBand = 1,
    Flags = 2,
    ShiftActivity = 3,
    WildlandSpecialtyApparatus = 4,
    StrikeTeams = 5,
    ReserveApparatusStatus = 6,
    OnCallList = 7,
    UsarStatus = 8,
    UsarResponse = 9,
    AdditionalEMSResponse = 10,
    CommunityRiskReduction = 11,
    EmergencyOperationsCenter = 12
}

export enum MonroviaOrder {
    OperationsBC = 0,
    StaffingCaptain = 1,
    BadgeBand = 2,
    Flags = 3,
    LeadInvestigator = 5,
    ShiftInvestigator = 4,
    ShiftActivity = 6,
    WildlandStatus = 7,
    StrikeTeams = 8,
    AparatusStatus = 9,
    TrainingBox = 10,
    UsarResponse = 11

}

export enum Demo1ModuleOrder {
    OperationsBC = 0,
    StaffCaptain = 1,
    BadgeBand = 2,
    Flags = 3,
    ShiftActivity = 4,
    WildlandSpecialtyApparatus = 5,
    StrikeTeams = 6,
    ReserveApparatusStatus = 7,
    OnCallList = 8,
    UsarResponse = 9,
    HazmatResponse = 10,
    PublicEducation = 11,
    CommunityRoom = 12,
    SmallConferenceRoom = 13
}

export enum Demo2ModuleOrder {
    OperationsBC = 0,
    StaffCaptain = 1,
    BadgeBand = 2,
    Flags = 3,
    ShiftActivity = 4,
    WildlandSpecialtyApparatus = 5,
    StrikeTeams = 6,
    ReserveApparatusStatus = 7,
    OnCallList = 8,
    UsarResponse = 9,
    HazmatResponse = 10,
    PublicEducation = 11,
    CommunityRoom = 12,
    SmallConferenceRoom = 13
}

export enum Demo3ModuleOrder {
    OperationsBC = 0,
    StaffCaptain = 1,
    BadgeBand = 2,
    Flags = 3,
    ShiftActivity = 4,
    WildlandSpecialtyApparatus = 5,
    StrikeTeams = 6,
    ReserveApparatusStatus = 7,
    OnCallList = 8,
    UsarResponse = 9,
    HazmatResponse = 10,
    PublicEducation = 11,
    CommunityRoom = 12,
    SmallConferenceRoom = 13
}

export enum Demo4ModuleOrder {
    OperationsBC = 0,
    StaffCaptain = 1,
    BadgeBand = 2,
    Flags = 3,
    ShiftActivity = 4,
    WildlandSpecialtyApparatus = 5,
    StrikeTeams = 6,
    ReserveApparatusStatus = 7,
    OnCallList = 8,
    UsarResponse = 9,
    HazmatResponse = 10,
    PublicEducation = 11,
    CommunityRoom = 12,
    SmallConferenceRoom = 13
}

export enum Demo5ModuleOrder {
    OperationsBC = 0,
    StaffCaptain = 1,
    BadgeBand = 2,
    Flags = 3,
    ShiftActivity = 4,
    WildlandSpecialtyApparatus = 5,
    StrikeTeams = 6,
    ReserveApparatusStatus = 7,
    OnCallList = 8,
    UsarResponse = 9,
    HazmatResponse = 10,
    PublicEducation = 11,
    CommunityRoom = 12,
    SmallConferenceRoom = 13
}


export enum WestCovinaModuleOrder {
    OperationsAC = 0,
    StaffCaptain = 1,
    MourningBand = 2,
    Flags = 3,
    ShiftActivity = 4,
    WildlandStatus = 5,
    StrikeTeams = 6,
    REMSTeam = 7,
    ReserveApparatusStatus = 8,
    SingleResource = 9,
    Backfill = 10,
    UsarResponse = 11,
    DailyTraining = 12,
    PublicEducation = 13,
    FirePrevention = 14,
    OverheadTeams = 15
}

export enum AvalonModuleOrder {
    StaffCaptain = 0,
    MourningBand = 1,
    Flags = 2,
    Note = 3,
    Training = 4
}

export enum StrikeTeamsAnswerOrder {
    STOptions = 0,
    StrikeTeam = 1,
    StrikeTeamGroup = 2
}

export enum StrikeTeamsOptionOrder {
    StrikeTeam = 0,
    Engine = 1
}

export interface PreviousDayDto {
    questionGroupId: number,
    calendarDate: Date
}

export interface FormCalendarDto {
    formId: number,
    calendar: CalendarDto
}

export interface QuestionGroupCalendarDto {
    questionGroups: QuestionGroupDto[],
    calendarDate: Date
}

export const OtherModelId: number = -1;
export const NewModelId: number = -2;
export const SaveTimeoutInMilliseconds: number = 500;

export interface IsolatedSelectItem {
    id: number | null,
    name: string
}

export interface StrikeTeamsIsolatedSelectItem {
    id: string,
    name: string
}

export interface StrikeTeamUpdateOptions {
    incidentName?: string,
    statusOptionId?: number,
    referenceDetails?: ReferenceDetails
}

export enum StrikeTeamSelectionType {
    Option,
    User
}
